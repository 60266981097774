/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as e } from "./handleUtils.js";
function n(e) {
  return t(e) || r(e);
}
function t(e) {
  return null != e && "object" == typeof e && "on" in e && "function" == typeof e.on;
}
function r(e) {
  return null != e && "object" == typeof e && "addEventListener" in e && "function" == typeof e.addEventListener;
}
function o(e, t, o) {
  if (!n(e)) throw new TypeError("target is not a Evented or EventTarget object");
  return r(e) ? i(e, t, o) : e.on(t, o);
}
function i(n, t, r, o) {
  if (Array.isArray(t)) {
    const i = t.slice();
    for (const e of i) n.addEventListener(e, r, o);
    return e(() => {
      for (const e of i) n.removeEventListener(e, r, o);
    });
  }
  return n.addEventListener(t, r, o), e(() => n.removeEventListener(t, r, o));
}
function c(e, t, r) {
  if (!n(e)) throw new TypeError("target is not a Evented or EventTarget object");
  if ("once" in e) return e.once(t, r);
  const i = o(e, t, n => {
    i.remove(), r.call(e, n);
  });
  return i;
}
function f(e, n, t) {
  let r = !1;
  const i = o(e, n, n => {
    r || t.call(e, n);
  });
  return {
    resume() {
      r = !1;
    },
    pause() {
      r = !0;
    },
    remove() {
      i.remove();
    }
  };
}
export { i as addEventListener, n as isEventedOrEventTarget, o as on, c as once, f as pausable };